import { z } from 'zod';
import { getBuyerNavbarLayout } from '@layout/buyers/NavbarLayout';
import CallbackSection from '@features/home/CallbackSection/CallbackSection';
import ExploreSection from '@features/home/ExploreSection/ExploreSection';
import Footer from '@features/home/Footer/Footer';
import HeroSection from '@features/home/HeroSection/HeroSection';
import PartnershipsSection from '@features/home/PartnershipsSection/PartnershipsSection';
import RecommendationsSection from '@features/home/RecommendationsSection/RecommendationsSection';
import TestimonialsSection from '@features/home/TestimonialsSection/TestimonialsSection';
import { getStaticPropsNavbarHOC } from '@server/getStaticPropsNavbarHOC';
import { axiosAuth } from '@libs/axios';
import { getNavigationTreeSchema } from '@api/common/browse';
import { useAuth } from '@hooks/useAuth';
import { type LandingPageDataType, useHomepageCMS } from '@hooks/useHompageCMS';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import useRecommendations from '@hooks/useRecommendations';
import { landingPageMock } from '@data/mocks/landingPageMock';
import { ROLES } from '@constants';

type HomeProps = {
  navbarData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  landingPageData: LandingPageDataType;
};

const Home = ({ landingPageData, navbarData }: HomeProps) => {
  const cmsData = useHomepageCMS(landingPageData);

  const {
    loadingBrands,
    loadingProducts,
    loadingCollections,
    brands,
    products,
    collections,
    errorBrands,
    errorProducts,
    errorCollections,
  } = useRecommendations(cmsData);
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();

  const { user } = useAuth();
  const isBrandUser = user?.role === ROLES.BRAND;

  return (
    <div className="font-body text-base">
      <HeroSection data={cmsData?.landingPageTop} />
      {!isNewBalanceUser && !isBrandUser && (
        <RecommendationsSection
          brands={brands}
          products={products}
          collections={collections}
          loadingBrands={loadingBrands}
          loadingCollections={loadingCollections}
          loadingProducts={loadingProducts}
          errorBrands={errorBrands}
          errorCollections={errorCollections}
          errorProducts={errorProducts}
        />
      )}
      {!isBrandUser && (
        <ExploreSection
          categories={cmsData?.exploreCategories}
          curations={cmsData?.exploreCurations}
          tags={cmsData?.trendingTags}
        />
      )}
      {!isNewBalanceUser && !isCityBeachUser && !isKidStuffUser && (
        <>
          <PartnershipsSection brandsShowcase={cmsData?.brandsShowcase} />
          <TestimonialsSection data={cmsData?.customerTestimonial} />
          <CallbackSection data={cmsData?.requestCallback} />
        </>
      )}
      {(isNewBalanceUser || isCityBeachUser || isKidStuffUser) && <div className="mb-12"></div>}
      <Footer navbarData={navbarData} data={cmsData?.footer} />
    </div>
  );
};

Home.layout = getBuyerNavbarLayout;

export const getStaticProps = getStaticPropsNavbarHOC(async () => {
  let landingPageData = { default: {} };
  if (process.env.NODE_ENV === 'development') {
    // mocking on local dev to speed up development
    landingPageData.default = landingPageMock;
  } else {
    const folderPaths =
      process.env.LANDING_PAGE_CMS_PATHS && process.env.LANDING_PAGE_CMS_PATHS !== 'null'
        ? process.env.LANDING_PAGE_CMS_PATHS.split(',')
        : [];

    const [landingPageResponse, ...specificLandingPageResponses] = await Promise.all([
      axiosAuth.get('/cms/get-landing-page-content'),
      ...folderPaths.map(path => {
        return axiosAuth.get(`/cms/get-landing-page-content?path=${path}`);
      }),
    ]);

    landingPageData = {
      default: landingPageResponse.data,
      ...Object.fromEntries(
        specificLandingPageResponses.map((res, index) => [folderPaths[index], res.data])
      ),
    };
  }

  return {
    props: {
      showCompactFooter: false,
      landingPageData,
    },
  };
});

export default Home;
